import React from 'react'
import './about.css'
import {GiDiploma} from 'react-icons/gi'

import AlgoExpert from '../../assets/algoexpert.png';
import headshot from '../../assets/pedram_headshot.png';

import {aboutLink, aboutId} from '../nav/navLinks'
import {useNav} from '../nav/hooks/useNav'
    // useNav takes in a navLinkId and returns a ref
	// this ref is used to register the navLinkId that's
	// currently in view, and apply activeClass styling
	// to the corresponding nav childElement


// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import {Autoplay, 
    // Navigation, 
    Pagination, Mousewheel, Keyboard } from "swiper";


const texas = "https://mccombs.utexas.edu/media/mccombs-website/site-assets/images/utilityNav-logo.svg"
// const texas2 = "https://mccombs.utexas.edu/media/mccombs-website/content-assets/images/university-texas-white.svg"
// const texas3 = "https://mccombs.utexas.edu/media/mccombs-website/site-assets/images/mccombs-informal-logo.svg"
const quantic = "https://upload.wikimedia.org/wikipedia/commons/thumb/0/00/Quantic-logo-color.svg/492px-Quantic-logo-color.svg.png?20210703091448"
const udacity = "https://www.udacity.com/images/svgs/udacity-tt-logo.svg"

const dataStructures = "https://graduation.udacity.com/nd256"
const fullStack = "https://graduation.udacity.com/nd0044"
const frontEnd = "https://graduation.udacity.com/nd0011"
const reactND = "https://graduation.udacity.com/nd019"




const About = () => {
    const aboutRef = useNav(aboutLink);
    // const profile_pic = "https://scontent-dfw5-2.xx.fbcdn.net/v/t1.6435-9/68574398_10157595430107458_1831896932922949632_n.jpg?_nc_cat=102&ccb=1-5&_nc_sid=09cbfe&_nc_ohc=Cp8Zw4mjM8sAX-JYnuh&_nc_ht=scontent-dfw5-2.xx&oh=00_AT85rSjvG50a6rD0xgGUA1jU5vlFUyvEu99gDIJrMe1nKQ&oe=6295C8D2";
    return (
        <section id={aboutId} ref={aboutRef}>
            
            {/* <h5>Get to Know</h5> */}
            {/* <h2> About Me</h2> */}
            <h2> My Background</h2>

            <div className="container about__container">
                <div className="about__me">
                    <div className="about__me-image">
                        <img src={headshot} alt="About Pedram Rahmanian"/>
                    </div>
                </div>

                <div className="about__content">
                    {/* <div className="about__cards"> */}

                        <Swiper
                            slidesPerView={1}
                            loop={true}
                            spaceBetween={40}
                            cssMode={true}
                            // navigation={true}
                            pagination={{clickable:true}}
                            mousewheel={true}
                            keyboard={true}
                            centeredSlides={true}
                            autoplay={{
                                delay: 3500,
                                disableOnInteraction: true,
                            }}
                            modules={[ Autoplay, Pagination, Mousewheel, Keyboard]}
                            // modules={[Autoplay, Navigation, Pagination, Mousewheel, Keyboard]} 
                            className="about__slider"
                        >

                            <SwiperSlide className="about__card">
                                <img className="card__logo white-logo" src={texas} alt="MBA from the Quantic School of Business and Technology"/>
                                <div className="detail__line__flex">
                                    <GiDiploma className='about__icon'/> 
                                    <h5>BBA '14</h5>
                                </div>
                                <small>Consulting and Change Management with a minor in Marketing</small>
                            </SwiperSlide>

                            <SwiperSlide className="about__card">
                                <img className="card__logo" src={quantic} alt="MBA from the Quantic School of Business and Technology"/>
                                <div className="detail__line__flex">
                                    <GiDiploma className='about__icon'/> 
                                    <h5>MBA '18</h5>
                                </div>
                                <small>Master of Business Administration from the Quantic School of Business and Technology</small>
                            </SwiperSlide>

                            <SwiperSlide className="about__card">
                                <img className="card__logo white-logo" id="udacity" src={udacity} alt="Udacity NanoDegree Programs"/>
                                <div className="detail__line__flex">
                                    <GiDiploma className='about__icon'/> 
                                    <h5>Technical NanoDegrees</h5>
                                </div>
                                <div className="detail__line__udacity-links">
                                    <a className="udacity_link" href={reactND}>React + Redux</a>
                                    <a className="udacity_link" href={dataStructures}>Data Structures + Algorithms</a>
                                    <a className="udacity_link" href={fullStack}>Full Stack Web Development</a>
                                    <a className="udacity_link" href={frontEnd}>Front End Web Development</a>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide className="about__card">
                                <img className="card__logo" src={AlgoExpert} alt="AlgoExpert Certified"/>
                                <div className="detail__line__grid">
                                    <a href="https://certificate.algoexpert.io/AlgoExpert%20Certificate%20AE-a43e15c058"><h5>AlgoExpert Certified</h5></a>
                                    <GiDiploma className='about__icon'/>
                                    <a href="https://certificate.algoexpert.io/FrontendExpert%20Certificate%20FE-e3b8a1c9f9"><h5>FrontEndExpert Certified</h5></a>
                                </div>
                            </SwiperSlide>

                        </Swiper>


                    {/* </div> */}

                    <p>
                        After years of feeling stiffled by a shortcoming of technical skills, I dedicated the last 3 years to becoming a full fledged software engineer.
                    </p>

                    <p>
                        I'm currently seeking a full time role as a software developer as well as new freelance clients.
                    </p>
                    <a href="#contact" className='btn btn-primary'>Let's Talk</a>
                </div>
            </div>
        </section>
    )
}

export default About
