import React from 'react'
import './services.css'
import {BiCheck} from 'react-icons/bi'
import icons from './services-icons'

const Service = ({data}) => {
    const {title, tools, services, description} = data
    return (
        <div className="service">
            <div className="service__head">
                <h3>{title ?? "placeholder"}</h3>
            </div>

            <p className="service__description">
                {description ?? <p>placeholder</p>}
            </p>
            <ul className="service__list">
                {services && services.map((service, index) => (
                    <li key={index}>
                        <BiCheck className='service__list-icon' />
                        <p>{service}</p>
                    </li>
                ))}
            </ul>

            <div className="tools">
                {tools && tools.map(tool => icons[tool])}
            </div>

        </div>
    )
}

export default Service
