import {FaReact, FaArrowUp} from 'react-icons/fa'
import {MdSchool, MdOutlineDesignServices, MdWork} from 'react-icons/md'
import {BsStarHalf, BsFillChatQuoteFill} from 'react-icons/bs'

export const headerLink = "top";
export const headerId = "header";

export const experienceLink = "skills";
export const experienceId = "experience";

export const aboutLink = "background";
export const aboutId = "about";

export const servicesLink = "offerings"
export const servicesId = "services"


export const portfolioLink = "work"
export const portfolioId = "portfolio"


export const testimonialsLink = "reviews"
export const testimonialsId = "testimonials"

export const contactLink = "form"
export const contactId = "contact"

const navLinks = [
    {
        navLinkId: headerLink,
        scrollToId: headerId,
        icon: <FaArrowUp />, 
        text: "top",
    },
    {
        navLinkId: experienceLink,
        scrollToId: experienceId,
        icon: <FaReact />, 
        text: "skills",
    },
    {
        navLinkId: aboutLink,
        scrollToId: aboutId,
        icon: <MdSchool />, 
        text: "background",
    },
    {
        navLinkId: servicesLink,
        scrollToId: servicesId,
        icon: <MdOutlineDesignServices />, 
        text: "services",
    },
    {
        navLinkId: portfolioLink,
        scrollToId: portfolioId,
        icon: <MdWork />, 
        text: "portfolio",
    },
    {
        navLinkId: testimonialsLink,
        scrollToId: testimonialsId,
        icon: <BsStarHalf />, 
        text: "testimonials",
    },
    {
        navLinkId: contactLink,
        scrollToId: contactId,
        icon: <BsFillChatQuoteFill />, 
        text: "contact",
    },
]

export default navLinks;

/*
navLink object format::

{
    navLinkId: ,
    scrollToId: ,
    icon: , 
    text: ,
},

*/
