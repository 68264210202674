import zach from '../../assets/zach_watkins.jpeg'
import brittany from '../../assets/brittany_draper.jpeg'
import sarah from '../../assets/sarah_hollingsworth.jpeg'

const testimonials = [
    {
        include: true,
        name: "Brittany Draper",
        src: brittany,
        image: "https://media-exp1.licdn.com/dms/image/C4E03AQEagbhJqR3Oyw/profile-displayphoto-shrink_200_200/0/1579033081894?e=1657152000&v=beta&t=aKA6kPpyKSN-1fzqw6IL1hPdlpvvB28tl-s-VQ07oUs",
        // date: 10/12/2015,
        date: new Date(2015, 10, 12),
        link: "https://www.linkedin.com/in/prahmanian/details/recommendations/",
        testimonial: "Pedram is one of the greatest individuals I’ve ever had the pleasure of working with. He is the first to volunteer when someone needs help, and he always takes the initiatives and gets projects done quickly and efficiently. Pedram is someone you can count on to get the job done, and he always does so with a positive attitude. Anyone would be lucky to have Pedram on their team. ",
    },
    {
        include: true,
        name: "Zach Watkins",
        src: zach,
        image: "https://media-exp1.licdn.com/dms/image/C4E03AQH4rLI35JuIog/profile-displayphoto-shrink_100_100/0/1517749083287?e=1657152000&v=beta&t=_rgq_5u-Axfbd89S8zj3Ti1S2jeSOlj6zf3XtArO8Ls",
        // date: date(10/7/2015),
        date: new Date(2015, 10, 7),
        link: "https://www.linkedin.com/in/prahmanian/details/recommendations/",
        testimonial: `Pedram is one of those rare gems that are truly valuable when you find them. He showed great initiative, thorough understanding of business problems and processes, in depth knowledge and research across several functional areas, and a keen ability to articulate solutions. All of these qualities made him refreshing to work with on a daily basis. Pedram showcased his abilities while handling strategic vendors specific to marketing, CRM, and automation tools - oftentimes having a more developed understanding of products and workflows than the representatives with whom he was speaking. I highly recommend Pedram in any and every capacity. His flexibility to tackle any problem and always positive attitude make him a tremendous asset and he will continue to provide value in many different capacities throughout his career.`,
    },
    {
        include: true,
        name: "Sarah Hollingsworth",
        src: sarah,
        image: "https://media-exp1.licdn.com/dms/image/C5603AQFNnXfy2wjoFA/profile-displayphoto-shrink_100_100/0/1614451470580?e=1657152000&v=beta&t=aaLMDQ7a89QVkfd-zCnV_34UHU6uqVN8MxTx1HKzcdw",
        // date: 12/13/2021,
        date: new Date(2021, 12, 13),
        link: "https://www.linkedin.com/in/prahmanian/details/recommendations/",
        testimonial: `I hired Pedram for a proof of concept project for my startup, Poppylist. The goal was to migrate our blog from Squarespace to Webflow. Pedram was responsive, thorough, and knowledgeable. He shared detailed insights, and he proactively offered recommendations for improving our SEO. We look forward to continuing our work with Pedram in the future!`,
    },
]

export default testimonials

// template

// {
//     include: true,
//     name: "",
//     image: "",
//     // date: 12/13/2021,
//     date: new Date(2021, 12, 13),
//     link: "https://www.linkedin.com/in/prahmanian/details/recommendations/",
//     testimonial: ``,
// },