

// Model

// "Web Development" : {
//     title: "Web Development",
//     tools: [

//     ],
//     services: [

//     ],
//     description: `
    
//     `,

// },

const services = {
    "Custom Web Development" : {
        title: "Custom Web Development",
        tools: [
            "html",
            "css",
            "javascript",
            "react",
            "redux",
            "next",
        ],
        services: [
            "Technology Consultation",
            "Site Map Consultation",
            "Custom Site Development",
            "Landing Page Development",
            "Style Guide Implementation",
            "Data Structure Design and Implementation",

        ],
        description: `
            Wether you
        `,
    },


    "Platform Web Development" : {
        title: "Platform Web Development & Management",
        tools: [
            "webflow",
            "squarespace",
            "wix",
            "shopify",
            "bigcommerce",
            "zapier",
        ],
        services: [
            "Technical & Vision Consultation",
            "Site Buildout",
            "Platform & Blog Migration",
            "Custom Style Rule Overrides",
            "Integrations Implementation",

        ],
        description: `
            
        `,
    
    },

    "ecommerce" : {
        title: "Ecommerce Buildout",
        tools: [
            "shopify",
            "bigcommerce",
            "deliverr",
        ],
        services: [
            "Technical & Platform Consultation",
            "Store Buildout and Setup",
            "Vendor Data Processing", 
            "Catalog Ingestion",
            "Template and Theme Customization",
            "Omnichannel & Fulfillment Integrations",
            "Plugin & Extensions Implementation",
        ],
        description: ``,
    
    },

    "content" : {
        title: "Content Creation",
        tools: [
            "camera",
            "photoshop",
            "lightroom",
        ],
        services: [
            "Staged Product Photography",
            "Photo Post-Processing & Editing",
            "Background Removal",
            "Image Compression",
        ],
        description: `
        
        `,
    
    },

    "data" : {
        title: "Data Processing & Management",
        tools: [
            "sheets",
            "airtable",
            "excel",
            "zapier",
        ],
        services: [
            "Custom Script & Function Development",
            "Workflow & Worksheet Development",
            "Data Cleaning & Standardization",
            "Automations",
            "Database Development & Integration",
            "Data Management",

        ],
        description: `
        
        `,
    
    },


    "product" : {
        title: "Product Development",
        tools: [
            "autocad",
            "coreldraw",
            "laser",
        ],
        services: [
            "Design Thinking",
            "Product, Materials, & Process Consultation",
            "CAD/CAM Product Design",
            "Laser / CNC Subtractive Manufacturing Prototyping",
            "Short-run Product Manufacturing",
            "Growth Consultation",
            "Material and Vendor Sourcing",
        ],
        description: `
        
        `,
    
    },

}



export default services;