import React from 'react'
import './header.css'
import CTA from './CTA'
import Socials from './Socials'
import MyImage from '../../assets/headshot.png'

import {headerId, headerLink} from '../nav/navLinks'
import {useNav} from '../nav/hooks/useNav'

const Header = () => {
    // useNav takes in a navLinkId and returns a ref
	// this ref is used to register the navLinkId that's
	// currently in view, and apply activeClass styling
	// to the corresponding nav childElement

    const headerRef = useNav(headerLink);
    
    return (
        <header id={headerId} ref={headerRef}>
            <div className="container header__container">
                <h5>Hello I'm</h5>
                <h1>Pedram Rahmanian</h1>

                <h5 className="text_light">Fullstack Developer</h5>

                <CTA />
                <Socials />


                <div className="my_image">
                    <img id="profile" src={MyImage} alt="headshot of pedram rahmanian"/>
                </div>




                <a href="#contact" className="scroll__down">Scroll Down</a>
            </div>
        </header>
    )
}

export default Header
