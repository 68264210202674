import React from 'react'
import './nav.css'

import navLinks from './navLinks'
import NavLink from './NavLink'

// react scroll
// https://www.npmjs.com/package/react-scroll
// React Single Page Nav
// https://medium.com/geekculture/scrollable-single-page-site-navigation-with-react-custom-hooks-4e7af716f6b1



const Nav = () => {

    return (
        <nav id='nav'>
            {navLinks.map(
                ({navLinkId, scrollToId, icon, text}, index) =>
                <NavLink
                    key={index}
                    navLinkId={navLinkId}
                    scrollToId={scrollToId}
                    icon={icon}
                    text={text}
                />
            )}

        </nav>
    )
}

export default Nav
