import React, {useContext} from 'react'
import { NavContext } from './context/NavContext'

// https://www.npmjs.com/package/react-scroll
import { Link} from 'react-scroll'


const NavLink = ({navLinkId, scrollToId, icon, text}) => {
    const {activeNavLinkId, setActiveNavLinkId } = useContext(NavContext)
    const handleClick = () => {
        console.log(`registered click to ${scrollToId}`)
        setActiveNavLinkId(navLinkId);
        document.getElementById(scrollToId).scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
    };
    
    return (
        // <div 
        //     id={navLinkId}
        //     className={activeNavLinkId === navLinkId ? 'active link a' : 'link a'}
        //     onClick={handleClick}
        // >
        //     {icon}
        //     <span className="text">{text}</span>
        // </div>


        

        <Link 
            id={navLinkId}
            activeClass="active"
            to={scrollToId}
            spy={true}
            smooth={true}
            hashSpy={true}
            offset={-75}
            duration={200}
            // delay={1000}
            isDynamic={true}
            ignoreCancelEvents={false}
            // spyThrottle={50}
            className={activeNavLinkId === navLinkId ? 'active link a' : 'link a'}
            onClick={handleClick}
        >
            {icon}
            <span className="text">{text}</span>
        </Link>

        
    )
}

export default NavLink
