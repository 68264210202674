import React from 'react'
import Header from './components/header/Header'
import Nav from './components/nav/Nav'
import About from './components/about/About'
import Experience from './components/experience/Experience'
import Services from './components/services/Services'
import Portfolio from './components/portfolio/Portfolio'
import Testimonials from './components/testimonials/Testimonials'
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer'
import NavProvider from './components/nav/context/NavContext'

const App = () => {
    return (
        <div className='appContainer'>
            <NavProvider>
                <Nav />
                <main>
                    <Header />
                    <Experience />
                    <About />
                    <Services />
                    <Portfolio />
                    <Testimonials />
                    <Contact />
                    <Footer />
                </main>
            </NavProvider>
            
        </div>
    )
}

export default App
