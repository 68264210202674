import {useState, useEffect} from 'react';

export const useOnScreen = ref => {
    const [isOnScreen, setOnScreen] = useState(false);

    const observer = new IntersectionObserver(
        ([entry]) => setOnScreen(entry.isIntersecting),
        {
            // root: document.querySelector(rootElementSelector),
            // rootMargin: '-20%',
            // rootMargin: '-35%',
            // threshold: [0.3,0.6,0.9],
            threshold: [0.5],
            // threshold: [0.1,0.3,0.5,0.7,0.9],
        }
    );

    useEffect(() => {
        observer.observe(ref.current);
        return () => {
            observer.disconnect();
        };
    });

    return isOnScreen;
}