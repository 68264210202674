import React from 'react'
import './experience.css'
import Skill from './Skill'
import {FaHtml5} from 'react-icons/fa'
import {DiCss3} from 'react-icons/di'
import {DiJavascript} from 'react-icons/di'
import {FaReact} from 'react-icons/fa'
import {SiRedux} from 'react-icons/si'
import {FaPython} from 'react-icons/fa'

import {experienceLink, experienceId} from '../nav/navLinks'
import {useNav} from '../nav/hooks/useNav'
    // useNav takes in a navLinkId and returns a ref
	// this ref is used to register the navLinkId that's
	// currently in view, and apply activeClass styling
	// to the corresponding nav childElement

const Experience = () => {
    const experienceRef = useNav(experienceLink);
    return (
        <section id={experienceId} ref={experienceRef}>
            {/* <h5>What Level of Experience I Have</h5> */}
            <h2>My Technical Skills</h2>

            <div className="container experience__container">

                <div className="experience__frontend">
                    {/* <h3>Frontend Development</h3> */}
                    <div className="experience__content">

                        <Skill icon={<DiJavascript className='experience__icon'/>} skill='JavaScript' level='Experienced' />
                        <Skill icon={<FaReact className='experience__icon'/>} skill='React' level='Experienced' />
                        <Skill icon={<SiRedux className='experience__icon'/>} skill='Redux' level='Moderate' />
                        <Skill icon={<FaHtml5 className='experience__icon'/>} skill='HTML' level='Experienced' />
                        <Skill icon={<DiCss3 className='experience__icon'/>} skill='CSS' level='Experienced' />
                        <Skill icon={<FaPython className='experience__icon'/>} skill='Python' level='Experienced' />
                        
                    </div>
                </div>
            


                {/* <div className="experience__backend">
                    <h3>Backend Development</h3>
                    <div className="experience__content">
                        <Skill icon={<FaPython className='experience__icon'/>} skill='Python' level='Experienced' />
                        <Skill icon={<DiJavascript className='experience__icon'/>} skill='JavaScript' level='Experienced' />
                        <Skill icon={<FaReact className='experience__icon'/>} skill='React' level='Experienced' />
                    </div>
                </div> */}
                
            </div>

        </section>
    )
}

export default Experience
