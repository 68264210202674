import React from 'react'

const Deliverr = () => {
    return (
        <svg className="svg_icon service_icon" clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 40 25">
            <g fill="#fff" >
                
                <path d="m38.829 1.529c-.514-.822-1.444-1.29-2.415-1.29h-36.414l4.459 4.795c1.417 1.524 3.403 2.388 5.483 2.388h21.267c1.686 0 3.197.872 4.038 2.335.088.154.168.314.237.473l3.395-5.845c.522-.891.506-1.97-.05-2.856zm-7.617 7.796h-20.648l4.068 4.374c1.417 1.524 3.403 2.388 5.483 2.388h6.027c1.686 0 3.196.872 4.038 2.336.096.165.179.335.253.506l3.131-5.392c.503-.867.545-1.945.049-2.817-.498-.872-1.397-1.395-2.401-1.395zm-5.129 8.329c1.663.092 2.936 1.514 2.844 3.177s-1.515 2.936-3.177 2.844c-1.663-.092-2.936-1.515-2.844-3.177.092-1.663 1.514-2.936 3.177-2.844z"/>
                {/* <path d="m46.082 2.128v19.097h7.26c3.261 0 5.754-.849 7.48-2.575 1.754-1.726 2.631-4.055 2.631-6.932s-.877-5.178-2.658-6.932c-1.754-1.781-4.247-2.658-7.48-2.658zm6.986 15.261h-2.767v-11.425h2.74c2.274 0 3.863.521 4.767 1.535.932 1.013 1.398 2.438 1.398 4.219 0 3.507-1.644 5.671-6.138 5.671z"/> */}
                {/* <path d="m66.804 9.026c-1.398 1.316-2.11 3.069-2.11 5.234v.109c0 2.165.685 3.891 2.082 5.206 1.398 1.288 3.206 1.945 5.48 1.945 2.329 0 4.329-.849 5.945-2.52l-2.383-2.274c-.959.904-2.082 1.342-3.37 1.342-2.165 0-3.59-1.068-3.672-2.712h10.439c.028-.411.055-.85.055-1.316 0-2-.658-3.643-1.973-4.986s-3.013-2.028-5.123-2.028c-2.165 0-3.973.658-5.37 2zm5.37 1.371c1.644 0 2.986 1.013 2.986 2.301h-6.329c.11-1.288 1.562-2.301 3.343-2.301z"/> */}
                {/* <path d="m80.367 2.128h4.082v19.097h-4.082z" fillRule="nonzero"/> */}
                {/* <path d="m89.737 2.315c-.438-.438-1.034-.681-1.653-.676-.619-.005-1.214.238-1.652.676s-.682 1.033-.677 1.653c0 1.287 1.041 2.301 2.329 2.301s2.329-1.014 2.329-2.301c.005-.62-.238-1.215-.676-1.653zm-3.68 5.132v13.809h4.082v-13.809z"/> */}
                {/* <path d="m97.061 21.325-5.918-13.809h4.137l3.617 8.768 3.589-8.768h4.137l-5.918 13.809z" fillRule="nonzero"/> */}
                {/* <path d="m108.436 9.026c-1.397 1.316-2.109 3.069-2.109 5.234v.109c0 2.165.685 3.891 2.082 5.206 1.397 1.288 3.206 1.945 5.48 1.945 2.329 0 4.329-.849 5.945-2.52l-2.384-2.274c-.959.904-2.082 1.342-3.37 1.342-2.164 0-3.589-1.068-3.671-2.712h10.439c.027-.411.055-.85.055-1.316 0-2-.658-3.643-1.973-4.986s-3.014-2.028-5.124-2.028c-2.164 0-3.972.658-5.37 2zm5.37 1.371c1.644 0 2.987 1.013 2.987 2.301h-6.329c.109-1.288 1.561-2.301 3.342-2.301z"/> */}
                <g fillRule="nonzero">
                    {/* <path d="m122 21.137v-13.809h4.082v1.89c.631-1.397 2.11-2.192 4.001-2.192.357.007.714.034 1.068.083v3.836c-.451-.091-.91-.137-1.37-.138-2.329 0-3.699 1.316-3.699 3.59v6.74z"/> */}
                    {/* <path d="m132.286 21.137v-13.809h4.082v1.89c.63-1.397 2.11-2.192 4-2.192.358.007.715.034 1.069.083v3.836c-.451-.091-.91-.137-1.37-.138-2.329 0-3.699 1.316-3.699 3.59v6.74z"/> */}
                </g>
            </g>
        </svg>
    )
}

export default Deliverr
