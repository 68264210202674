import {BiCheck} from 'react-icons/bi'
import {FaShopify, FaReact, FaCss3, FaHtml5} from 'react-icons/fa'

import {BsCamera2} from 'react-icons/bs'

import {SiAirtable, SiGooglesheets, SiAdobelightroom, SiAdobephotoshop, SiZapier, SiBigcommerce, SiWix, SiSquarespace, SiWebflow, SiNextdotjs, SiRedux, SiJavascript} from 'react-icons/si'

import {RiFileExcel2Fill} from 'react-icons/ri'

import {GiLaserPrecision} from 'react-icons/gi'

import CorelDraw from '../../assets/CorelDraw';
import AutoCAD from '../../assets/AutoCAD';
import Deliverr from '../../assets/Deliverr';


const icons = {
    check: <BiCheck className="check_icon service_icon" alt="Check Icon"  key="Check Icon"  />,
    html: <FaHtml5 className="html_icon service_icon" alt="HTML Icon"  key="HTML Icon"  />,
    css: <FaCss3 className="css_icon service_icon" alt="CSS Icon"  key="CSS Icon"  />,
    javascript: <SiJavascript className="javascript_icon service_icon" alt="Javascript Icon"  key="Javascript Icon"  />,
    react: <FaReact className="react_icon service_icon" alt="React Icon"  key="React Icon"  />,
    redux: <SiRedux className="redux_icon service_icon" alt="Redux Icon"  key="Redux Icon"  />,
    next: <SiNextdotjs className="next_icon service_icon" alt="Next.js Icon"  key="Next.js Icon"  />,
    webflow: <SiWebflow className="webflow_icon service_icon" alt="Webflow Icon"  key="Webflow Icon"  />,
    squarespace: <SiSquarespace className="squarespace_icon service_icon" alt="Square Space Icon"  key="Square Space Icon"  />,
    wix: <SiWix className="wix_icon service_icon" alt="Wix Icon"  key="Wix Icon"  />,
    shopify: <FaShopify className="shopify_icon service_icon" alt="Shopify Icon"  key="Shopify Icon"  />,
    bigcommerce: <SiBigcommerce className="bigcommerce_icon service_icon" alt="Big Commerce Icon"  key="Big Commerce Icon"  />,
    zapier: <SiZapier className="zapier_icon service_icon" alt="Zapier Icon"  key="Zapier Icon"  />,
    deliverr: <Deliverr className="deliverr_icon service_icon" alt="Deliverr Icon"  key="Deliverr Icon"  />,
    camera: <BsCamera2 className="camera_icon service_icon" alt="Camera Icon"  key="Camera Icon"  />,
    photoshop: <SiAdobephotoshop className="photoshop_icon service_icon" alt="Photoshop Icon"  key="Photoshop Icon"  />,
    lightroom: <SiAdobelightroom className="lightroom_icon service_icon" alt="Lightroom Icon"  key="Lightroom Icon"  />,
    sheets: <SiGooglesheets className="sheets_icon service_icon" alt="Google Sheets Icon"  key="Google Sheets Icon"  />,
    airtable: <SiAirtable className="airtable_icon service_icon" alt="Airtable Icon"  key="Airtable Icon"  />,
    excel: <RiFileExcel2Fill className="excel_icon service_icon" alt="MicroSoft Excel Icon"  key="MicroSoft Excel Icon"  />,
    autocad: <AutoCAD className="autocad_icon service_icon" alt="AutoCAD Icon"  key="AutoCAD Icon"  />,
    coreldraw: <CorelDraw className="coreldraw_icon service_icon" alt="Corel Draw Icon"key="Corel Draw Icon"/>,
    laser: <GiLaserPrecision className="laser_icon service_icon" alt="Laser Icon"  key="Laser Icon"  />,




}

export default icons
