import React from 'react'
import './portfolio.css'
import Project from './Project'
import projects from './projects-data'

import {portfolioLink, portfolioId} from '../nav/navLinks'
import {useNav} from '../nav/hooks/useNav'
    // useNav takes in a navLinkId and returns a ref
	// this ref is used to register the navLinkId that's
	// currently in view, and apply activeClass styling
	// to the corresponding nav childElement

const Portfolio = () => {
    const portfolioRef = useNav(portfolioLink);
    return (
        <section id={portfolioId} ref={portfolioRef}>
            <h5>My Recent Work</h5>
            <h2>Portfolio</h2>

            <div className="container portfolio__container">
                {projects.map(project => (<Project data={project} key={project.name}/>))}
                <p>more projects coming soon...</p>
            </div>
        </section>
    )
}

export default Portfolio
