import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {BsGithub} from 'react-icons/bs'
import {BsInstagram} from 'react-icons/bs'

const Socials = () => {
    return (
        <div className="header__socials">
            <a href="https://linkedin.com/in/prahmanian" target="_blank" rel="noreferrer"><BsLinkedin/></a>
            <a href="https://github.com/prahmanian" target="_blank" rel="noreferrer"><BsGithub/></a>
            <a href="https://www.instagram.com/rahmanianpedram/?hl=en" target="_blank" rel="noreferrer"><BsInstagram/></a>
        </div>
    )
}

export default Socials
