import React from 'react'
import './experience.css'

const Skill = ({icon, skill, level}) => {
    return (
        <div className='experience__details'>
            {icon}
            <div>
                <h4>{skill}</h4>
                <small>{level}</small>
            </div>
            
        </div>
    )
}

export default Skill
