import React from 'react'

const CorelDraw = () => {
    return (
        <svg fill="#000000" className="svg_icon service_icon"  viewBox="0 0 50 50">
            <path d="M 22 0 C 15 0 10 7 10 14 C 10 29.5 23 42 23 42 C 23 42 14 29 14 14 C 14 2 22 0 22 0 z M 25 0 C 22.4 0 18 3.159 18 15 C 18 26.058 23.578406 40.737422 23.816406 41.357422 L 24.0625 42 L 25.9375 42 L 26.183594 41.357422 C 26.420594 40.737422 32 26.058 32 15 C 32 3.159 27.6 0 25 0 z M 28 0 C 28 0 36 2 36 14 C 36 29 27 42 27 42 C 27 42 40 29.5 40 14 C 40 7 35 0 28 0 z M 14 2 C 14 2 6 5 6 14 C 6 31 21.5 42 21.5 42 C 21.5 42 8 30 8 14 C 8 5.75 14 2 14 2 z M 36 2 C 36 2 42 5.75 42 14 C 42 30 28.5 42 28.5 42 C 28.5 42 44 31 44 14 C 44 5 36 2 36 2 z M 21 44 C 20.692 44 20.402891 44.141766 20.212891 44.384766 C 20.022891 44.627766 19.954297 44.943188 20.029297 45.242188 L 21.029297 49.242188 C 21.141297 49.686187 21.541 50 22 50 L 28 50 C 28.459 50 28.858703 49.687188 28.970703 49.242188 L 29.970703 45.242188 C 30.045703 44.943188 29.976109 44.627766 29.787109 44.384766 C 29.598109 44.141766 29.308 44 29 44 L 21 44 z"/>
        </svg>
    )
}

export default CorelDraw;




