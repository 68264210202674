import React from 'react'
// import Resume from '../../assets/Pedram_Rahmanian_Resume_March_2022.pdf'
import Resume from '../../assets/Pedram_Rahmanian_Resume_2022_v2.pdf'

const CTA = () => {
    return (
        <div className="cta">
            <a href={Resume} className="btn" download>Download Resume</a>
            <a href="#contact" className="btn btn-primary">Lets Talk</a>
        </div>
    )
}

export default CTA
