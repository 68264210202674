import offfset from '../../assets/offfset.png'
// import puzzably from '../../assets/puzzably.png'
// const puzzably = "https://cdn.shopify.com/s/files/1/0556/0549/3928/products/Puzzably-Criss-Cross-04__49153_1024x1024@2x.jpg?v=1616629156"
const puzzably = "https://cdn.shopify.com/s/files/1/0556/0549/3928/products/Criss_Cross_Cube_Shot_3__50101_1024x1024@2x.jpg?v=1616629143"
const projects = [
    {
        name: "Offfset",
        skills: [
            "html",
            "css",
            "javascript",
            "shopify",
            "sheets"
        ],
        image: offfset,
        github: null,
        link: "https://offfset.com",
        description: `
            I manage data operations for this after market wheel merchant. I've written dozens of custom scripts to ingest over 20,000 skus into the store from several different vendors.
        `,
    },

    {
        name: "Puzzably",
        skills: [
            "shopify",
            "autocad",
            "laser",
            "photoshop",
            "camera"
        ],
        image: puzzably ,
        github:  null,
        link: "https://puzzably.com",
        description: `
            I created this line of 3D puzzles. I designed the products in AutoCAD and fabricated them via CNC laser cutting. I took the product photos and built out the ecommerce site.
        ` ,
    },

    // {
    //     name: "",
    //     skills: [],
    //     image: ,
    //     github: ,
    //     link: ,
    //     description: ,
    // },

    // {
    //     name: "",
    //     skills: [],
    //     image: ,
    //     github: ,
    //     link: ,
    //     description: ,
    // },

    // {
    //     name: "",
    //     skills: [],
    //     image: ,
    //     github: ,
    //     link: ,
    //     description: ,
    // },
]

export default projects;

// {
//     name: "",
//     skills: [],
//     image: ,
//     github: ,
//     link: ,
//     description: ,
// },