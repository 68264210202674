import React from 'react'

const AutoCAD = () => {
    return (
        <svg fill="#000000" className="svg_icon service_icon" viewBox="0 0 50 50">
            <path d="M15.08,38.33c-1.67,4.63-2.88,8.47-3.52,10.6l-3.31-0.66c0.47-1.58,1.5-4.93,3.09-9.32 C12.03,38.81,13.36,38.59,15.08,38.33z"/>
            <path d="M17.34,37.98c0.17-0.03,0.35-0.05,0.53-0.08C17.02,43.24,17,45.97,17,46c0,0.38-0.21,0.73-0.55,0.89l-2.56,1.28 C14.63,45.77,15.8,42.15,17.34,37.98z"/>
            <path d="M25,16c-1.72,3.23-3.3,6.55-4.72,9.78l-4.61,2.28c2.51-5.77,5.62-12.1,9.33-18.17c2.01,3.29,3.98,6.87,5.86,10.66 l-2.88,1.42C27.04,19.97,26.05,17.97,25,16z"/>
            <path d="M36.48,33.2c-4.61,0.65-19.24,2.71-24.33,3.57c0.25-0.66,0.5-1.34,0.78-2.04c2.05-0.71,9.69-3.21,21.83-5.68 C35.38,30.47,35.95,31.86,36.48,33.2z"/>
            <path d="M32.28,36.93c1.72,4.63,3.03,8.65,3.83,11.24l-2.56-1.28C33.21,46.73,33,46.38,33,46C33,45.96,32.99,41.97,32.28,36.93z"/>
            <path d="M37.23,35.12c2.42,6.24,3.93,11.15,4.53,13.15l-3.33,0.66c-0.76-2.54-2.32-7.51-4.52-13.35 C35.33,35.38,36.49,35.22,37.23,35.12z"/>
            <path d="M45.71,45.71l-2,2c-0.01,0.01-0.02,0.02-0.03,0.02C42.87,45,40.39,37,36.28,27.51c-0.19-0.43-0.65-0.68-1.11-0.59 c-10.52,2.11-17.84,4.28-21.28,5.39c0.19-0.47,0.38-0.95,0.58-1.43l18.17-8.98c0.49-0.24,0.69-0.84,0.45-1.34 c-2.3-4.72-4.74-9.13-7.24-13.09C25.66,7.18,25.34,7,25,7s-0.66,0.18-0.85,0.47C13.65,24.08,7.66,43.21,6.33,47.74 c-0.06-0.05-0.12-0.12-0.16-0.19l-2-3c-0.15-0.22-0.21-0.5-0.15-0.77c0.25-1.1,6.14-27.08,15.13-41.31C19.34,2.18,19.66,2,20,2h10 c0.34,0,0.66,0.18,0.85,0.47c8.99,14.23,14.88,41.18,15.13,42.32C46.05,45.12,45.95,45.47,45.71,45.71z"/>
        </svg>
    )
}

export default AutoCAD
