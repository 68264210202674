import React from 'react'
import icons from '../services/services-icons'

const Project = ({data}) => {
    const {image, name, github, link, skills, description} = data;
    return (
        <div className="portfolio__item">
            <div className="portfolio__item-top">
                <div className="portfolio__item-image">
                    <img src={image} alt={name}/>
                </div>
                
                <div className="portfolio__item-cta">
                    {github && <a href={github} className="btn btn-primary" target='_blank' rel="noreferrer">Github</a>}
                    {link && <a href={link} className="btn btn-primary" target='_blank' rel="noreferrer">Live Demo</a>}
                </div>
            </div>
            <h3>{name}</h3>

            <div className="skills">
                {skills && skills.map(skill => icons[skill])}
            </div>

            <p className="description">{description}</p>
        </div>
    )
}

export default Project
