import React from 'react'
import './testimonials.css'

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import {Autoplay, Navigation, Pagination, Mousewheel, Keyboard } from "swiper";


import testimonials from './testimonials-data'

import {testimonialsId, testimonialsLink} from '../nav/navLinks'
import {useNav} from '../nav/hooks/useNav'
    // useNav takes in a navLinkId and returns a ref
	// this ref is used to register the navLinkId that's
	// currently in view, and apply activeClass styling
	// to the corresponding nav childElement

const Testimonials = () => {
    const testimonialsRef = useNav(testimonialsLink);
    return (
        <section id={testimonialsId} ref={testimonialsRef}>
            <h5>What My Peers and Clients Say About Me...</h5>
            <h2>Testimonials</h2>

            <Swiper
                slidesPerView={1}
                loop={true}
                spaceBetween={40}
                cssMode={true}
                navigation={true}
                pagination={{clickable:true}}
                mousewheel={true}
                keyboard={true}
                centeredSlides={true}
                autoplay={{
                    delay: 3500,
                    disableOnInteraction: true,
                }}
                modules={[Autoplay, Navigation, Pagination, Mousewheel, Keyboard]} 
                className="container testimonials__container"
            >
                {
                    testimonials.filter(t => t.include === true).sort((a,b)=> b.date - a.date).map(review => {
                        const {name, date, src, testimonial, link} = review;

                        return (
                            <SwiperSlide key={`${name}_${date}`} className="testimonial">
                                <div className="testimonial__image">
                                    <img src={src} alt={`${name}_image`}/>
                                </div>
                                <h5 className="testimonial__name">{name}</h5>
                                <a className="testimonial__link" href={link} target="_blank" rel="noreferrer">View on LinkedIn</a>
                                <div className="testimonial__text__container">
                                    <p className="testimonial__text">
                                        {testimonial}
                                    </p>
                                </div>
                                
                    
                            </SwiperSlide>
                        )
                    })
                }

            </Swiper>
        </section>
    )
}

export default Testimonials
