import React, {useRef} from 'react'
import emailjs from 'emailjs-com';
import './contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {RiMessengerLine} from 'react-icons/ri'
import { BsLinkedin} from 'react-icons/bs'

import headshot from '../../assets/pedram_headshot.png'

import {contactId, contactLink} from '../nav/navLinks'
import {useNav} from '../nav/hooks/useNav'
    // useNav takes in a navLinkId and returns a ref
	// this ref is used to register the navLinkId that's
	// currently in view, and apply activeClass styling
	// to the corresponding nav childElement

const Contact = () => {
    const contactRef = useNav(contactLink);
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        document.getElementById('submit').innerHTML='Sending...';
        emailjs.sendForm('service_a31j8ga', 'contact_form', form.current, 'muoP-9pjWyLMAvex5')
        .then((result) => {
            console.log(result.text);
            document.getElementById('nameInput').disabled = true;
            document.getElementById('email').disabled = true;
            document.getElementById('message').value = '';
            document.getElementById('message').setAttribute('placeholder', 'Your message was sent successfully!');
            document.getElementById('submit').innerHTML='Send Message';
        }, (error) => {
            console.log(error.text);
        });
    };

    return (
        <section id={contactId} ref={contactRef}>
            <h5>Lets Have a Conversation</h5>
            <h2>Contact Me</h2>

            <div className="container contact__container">

                <div className="contact__options">

                    <div className="linkedin contact__option">
                        <span className="li_logo contact__option-icon">Linked <BsLinkedin id="li_logo"/></span>
                        <img
                            className="li_img"
                            src={headshot} 
                            alt="Pedram Rahmanian on Linkedin"
                        />
                        <p id="name">Pedram Rahmanian</p>
                        <p className="tagline">Ecommerce Specialist, React Enthusiast</p>
                        <a href="http://www.linkedin.com/in/prahmanian" target="_blank" rel="noreferrer">See Profile</a>
                    </div>
                    <div className="contact__option show">
                        <div className="icon_wrapper">
                            <MdOutlineEmail className="contact__option-icon"/>
                            <h4>Email</h4>
                        </div>
                        
                        <p className="show__prompt">Hover to See Address</p>
                        <p className="show__detail">pedram@pedramrahmanian.com</p>
                    </div>

                    <div className="contact__option">
                        <div className="icon_wrapper">
                            <RiMessengerLine className="contact__option-icon"/>
                            <h4>Messenger</h4>
                        </div>
                        <a href="https://m.me/pedram.rahmanian">Send a Message</a>
                    </div>

                </div>

                <form ref={form} onSubmit={sendEmail}>
                    <input type="text" id='nameInput' name='name' placeholder='Your Full Name' required/>
                    <input type="email" id='email' name='email' placeholder='Your Email' required/>
                    <textarea rows="7" id='message' name='message' placeholder='Your Message' required></textarea>
                    <button id='submit' type='submit' className="btn btn-primary">Send Message</button>
                </form>
            </div>
        </section>
    )
}

export default Contact
