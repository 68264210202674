import React from 'react'
import './services.css'

import Service from './Service'
import services from './services-data'


import {servicesLink, servicesId} from '../nav/navLinks'
import {useNav} from '../nav/hooks/useNav'
    // useNav takes in a navLinkId and returns a ref
	// this ref is used to register the navLinkId that's
	// currently in view, and apply activeClass styling
	// to the corresponding nav childElement

const Services = () => {

    const servicesRef = useNav(servicesLink);

    return (
        <section id={servicesId} ref={servicesRef}>
            <h5>What I Can Do For You</h5>
            <h2>Services</h2>

            <div className="container">
                
                <div className=" services__container">
                    {Object.keys(services).map((key, index) => (<Service key={index} data={services[key]} />))}
                </div>
            
            </div>


        </section>
    )
}

export default Services
